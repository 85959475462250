import { createRouter, createWebHistory } from "vue-router";
// import Home from '@/views/Home.vue'
import About from '@/views/About.vue'
import Events from '@/views/Events.vue'
import News from '@/views/News.vue'
import Poems from '@/views/Poems.vue'
import Blog from '@/views/Blog.vue'
import Books from '@/views/Books.vue'
import Inspiration from '@/views/Inspiration.vue'
import Contact from '@/views/Contact.vue'
import TrashArt from '@/views/TrashArt.vue'
import NotFound from '@/views/404.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: About
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/events',
    name: 'events',
    component: Events
  },
  {
    path: '/news',
    name: 'news',
    component: News
  },
  {
    path: '/poems-and-essays',
    name: 'poems',
    component: Poems
  },
  {
    path: '/blog',
    name: 'blog',
    component: Blog
  },
  {
    path: '/books',
    name: 'books',
    component: Books
  },
  {
    path: '/inspiration',
    name: 'inspiration',
    component: Inspiration
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/trash-art',
    name: 'trash-art',
    component: TrashArt
  },
  {
    path: '/*',
    name: 'not-found',
    component: NotFound
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
