<template>
  <article class="news page">
    <h1>News &amp; Readings</h1>
    <hr />
    <div class="row">
      <div class="col-lg-2"> </div>
      <div class="col-lg-8">
        <ul class="events">

          
          <li>
            <img src="@/assets/images/one_of_them_was_mine.jpg"
                   alt="Book cover art, One of Them Was Mine" />
            <h3>May 2024</h3>
            <p> Susan was interviewed by Tom Maxedon of KJZZ about her book One of Them Was Mine.
              <a href="https://kjzz.org/content/1879829/she-lost-her-son-phoenix-police-shooting-her-new-poetry-collection-details-her">She lost her son to a Phoenix police shooting. Her new poetry collection details her heartbreak</a>
            </p>

          </li>

          <li>
            <h3>February 2024</h3>

            <p> Susan read from her book <i>One of Them Was Mine</i>, at <a href="https://www.peregrinebookcompany.com/search/site/Susan%20Vespoli"
                 target="_blank">Peregrine Book Company</a>. Her book is available through their website.</p>

            <div class="img-flex">
              <div class="half">
                <img src="@/assets/images/Peregrine-Book-Co-reading.jpg"
                     alt="Susan Reading at Peregrine" />
              </div>
              <div class="half">
                <img class="two-thirds"
                     src="@/assets/images/Books-at-Peregrine-Book-Co.jpg"
                     alt="Susan's books at Peregrine" />
              </div>
            </div>
          </li>

          <li>
            <h3>January , 2024</h3>
            <a href="https://www.ricardomoranwriter.com/news"
               target="_blank"><img style="border: 3px solid #fafafa"
                   src="@/assets/images/top-25.png"
                   alt="ONE Art's Top 25" /></a>
            <p><a href="https://oneartpoetry.com/2024/01/23/one-arts-top-25-most-read-poets-of-2023/"
                 target="_blank">Susan was on ONE ART's list of Top 25 Most-Read Poets of 2023.</a></p>
          </li>
          <li>
            <h3>Jan. 5, 2024</h3>

            <p> Susan read at Changing Hands Bookstore from her book <i>One of Them Was Mine</i>, which has been nominated for an Eric Hoffer Book Award by <b>Kelsay Books</b>.</p>
            <div class="img-flex">
              <div class="third">
                <img src="@/assets/images/susan_2.jpg"
                     alt="Reading from One of Them Was Mine" />
              </div>
              <div class="two-thirds">
                <img class="two-thirds"
                     src="@/assets/images/susan_1.jpg"
                     alt="Susan" />
              </div>
            </div>
          </li>
          <li>
            <h3>December, 2023</h3>
            <p><a href="http://misfitmagazine.net/archive/No-37/OneofThem.html"
                 target="_blank">One of Them Was Mine was reviewed in Misfit Magazine in December 2023.</a></p>
          </li>
          
          <li>
            <h3>November, 2023</h3>
            <p>The Poetry Distillery nominated Susan's poem, "After a Stress-Induced Ocular-Migraine" for Best of the Net 2024</p>
          </li>
          <li>
            <h3>June, 2023</h3>
            <p><a href="https://oneartpoetry.com/2023/07/01/one-arts-top-10-most-read-poets-of-june-2023/"
                 target="_blank"> Susan's poem "Driving My Granddaughter Back to Her Dad's House" was a top 10 most read poems in ONE ART: a journal of poetry.</a></p>
          </li>
          <li>
            <h3>December, 2022</h3>
            <p><a href="https://newversenews.blogspot.com/2022/11/our-pushcart-prize-nominees.html"
                 target="_blank"> Susan's poem "Before I Knew Adam Had Died" was nominated for the Pushcart Prize by New Verse News.</a></p>
          </li>
          <img src="" />
          <li>
            <h3>November, 2022</h3>
            
            <div class="img-flex">
              <div class="two-thirds">
                <p>Susan was the featured reader at Changing Hands Bookstore.</p>
              </div>
              <div class="third">
                <img src="/img/sus_reading.jpg"
                     alt="Susan at Changing Hands Bookstore" />
              </div>
              
            </div>
          </li>

          <li>
            <h3>November, 2022</h3>
            <p><a href="https://youtu.be/7Z8Wdj7q0r4"
                 target="_blank"> Her collection <i>Cactus as Bad Boy </i>&nbsp; was nominated for the Eric Hoffer Award by <b>Kelsay Books</b>.</a></p>
            <div class="yt-container">
              <iframe width="720"
                      height="400"
                      src="https://www.youtube.com/embed/7Z8Wdj7q0r4"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen></iframe>
            </div>
          </li>
          <li><a href="https://www.ricardomoranwriter.com/news"
               target="_blank"><img src="/img/ricardo-moran.jpg"
                   alt="Ricardo Moran" /></a>
            <h3>September, 2022</h3>
            <p> <a href="https://www.ricardomoranwriter.com/news"
                 target="_blank"> Ricardo Moran translated three poems from <i>Blame It on the Serpent </i>into Spanish. Go check them out on his website.</a></p>
          </li>
          <li>
            <h3>August 20, 2022</h3>
            <p><a href="https://m.youtube.com/watch?v=teLwPXMgQ9M">Peregrine Book Co Reading <i>Cactus as Bad Boy</i></a></p>
            <div class="yt-container"><iframe width="720"
                      height="400"
                      src="https://www.youtube.com/embed/teLwPXMgQ9M"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen></iframe></div>
          </li>
          <li><a href="https://momeggreview.com/2022/06/26/blame-it-on-the-serpent-by-susan-vespoli/"
               target="_blank"><img src="/img/MER.png"
                   alt="Book cover art, Serpent Descending by Marina Petro" /></a>
            <h3>June 26, 2022</h3>
            <p> <a href="https://momeggreview.com/2022/06/26/blame-it-on-the-serpent-by-susan-vespoli/"
                 target="_blank"> <i>Blame It on the </i>Serpent was reviewed for <b>Mom Egg Review </b>by Dylan Ward.</a></p>
          </li>
          <li>
            <h3>April 13, 2022</h3>
            <p><a href="https://m.youtube.com/watch?v=G8F3Jf72xzM">Peregrine Book Company discussing <i>Blame it on the Serpent</i></a></p>
            <div class="yt-container"><iframe width="720"
                      height="400"
                      src="https://www.youtube.com/embed/G8F3Jf72xzM"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen></iframe></div>
          </li>
          <li><img src="/img/peregrine-book-co.jpg"
                 alt="Peregrine Books Logo" />
            <h3>April 13, 2022</h3>
            <p><a href="https://www.peregrinebookcompany.com/celebrate-poetry-month-susan-vespoli">Celebrate Poetry Month with Susan Vespoli</a></p>
          </li>
          <li><img src="/img/npm-word.png"
                 alt="NPR - Word: Season 6 Episode 7" />
            <h3>January, 2022</h3>
            <p>Susan was <a href="https://kjzz.org/content/1742367/word-s6e7-seasons-endings-bring-reflection-and-look-forward">interviewed by Tom Maxedon of NPR/KJZZ's literary podcast, Word, </a>on January 1, 2022. </p>
            <figure>
              <figcaption>Listen to the clip:</figcaption>
              <audio controls
                     src="/assets/susan-vespoli-interview-on-kjzz.mp3">Your browser does not support the<code>audio</code>element.</audio>
            </figure>
          </li>
          <li>
            <h3>November, 2021</h3>
            <p>Susan's poem "Thinking of Love During Covid and Coups" <a href="https://newversenews.blogspot.com/2021/11/our-pushcart-prize-nominees.html">nominated for a Pushcart Prize by New Verse News. </a></p>
          </li>
          <li><img src="/img/rattle-top-10-issue-71.png"
                 alt="Rattle Newsletter top 10" />
            <h3>March, 2021</h3>
            <p>Made Top 10 list on Rattle for first quarter of 2021 </p>
          </li>
          <li>
            <h3>2019 </h3>
            <p>Susan's poem "My Son No Longer Missing" was one of the <a href="https://www.rattle.com/my-son-no-longer-missing-by-susan-vespoli/">top ten Rattle poems for the year.</a></p>
          </li>
          <li><img src="/img/Poetry reading Caffeine Corridor1.jpg" />
            <h3>Feb. 8, 2019 </h3>
            <p>Caffeine Corridor Poetry Reading</p>
          </li>
          <li>
            <h3>Feb. 22, 2018 </h3>
            <p>Susan’s poem "Chicken" was read at the release party of <a href="http://pactpress.com/2018/02/howling-up-to-the-sky-release/">Howling Up to the Sky anthology at </a>Quail Ridge Books in Raleigh, NC</p>
          </li>
          <li>
            <h3>August 1, 2016 </h3>
            <p>Susan’s poem "Sonogram" was featured at the one-year anniversary of "Mothers Are Making Art," a collaborative project among Mom Egg Review, the <a href="https://www.procreateproject.com/mama-international/">ProCreate Project</a>, the <a href="https://mommuseum.org/tag/susan-vespoli/">Museum of Motherhood</a>, and The Mother Magazine, <a href="http://momeggreview.com/2016/07/03/mothers-are-making-art/">Mothers Are Making Art (M.A.M.A.)</a></p>
          </li>
          <li>
            <h3>Sept. 15, 2016</h3>
            <p>District 4 Poetry Reading at Jarod’s Coffee House</p><img src="/img/poetry reading4.JPG" /><img src="/img/poetry reading5.JPG" />
          </li>
        </ul>
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@import '../assets/scss/_vars';
h1 {
  text-align: center;
}
.events {
  li {
    @include clearfix;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin: 1rem auto 2rem;
    padding: 0 0 1rem;
    img {
      float: right;
      margin: 0 0 1rem 2rem;
      max-width: 200px;
    }
    .img-flex {
      display: flex;
      max-width: 100%;
      img {
        width: auto;
        max-width: 100%;
        padding: 0;
        margin: 0;
        float: none;
        display: block;
      }
      .third {
        width: 38%;
        padding-right: 3%;
      }
      .half {
        width: 48%;
        padding-right: 2%;
      }
      .two-thirds {
        flex: 1;
      }
    }
  }
}
</style>
