<template>
  <article class="books page">
    <h1>Books</h1>
    <hr />
    <div class="container">

      <div class="row">
        <div class="col-md-4">
          <figure>
            <a href="https://www.finishinglinepress.com/product/blame-it-on-the-serpent-by-susan-vespoli/">
              <img src="@/assets/images/one_of_them_was_mine.jpg"
                   alt="Book cover art, One of Them Was Mine" />
            </a>
          </figure>
        </div>
        <div class="col-md-8">
          <h2>One of Them Was Mine</h2>
          <p class="subhead">
            <i>Note: Profits will be donated to advocacy groups such as The National Coalition for the Homeless and Mothers Against Police Brutality.</i>
          </p>
          <p class="subhead">
            <i> One of Them Was Mine</i>, released by
            <a href="https://kelsaybooks.com/products/one-of-them-was-mine">Kelsay Books</a>
            in July 2023 and also available on
            <a href="https://www.amazon.com/One-Them-Mine-Susan-Vespoli/dp/1639803610/">
              Amazon
            </a>. To purchase a signed copy, email Susan at
            <a href="
                 mailto:write.wild@gmail.com">write.wild@gmail.com</a>. Give her your mailing address and she will send you PayPal info (free shipping).
          </p>

          <p>“These extraordinary poems, written in the raw days of early grief are life-lines, elegies, ghost messages, and cradle-songs. They are space blankets to wrap around the unhoused soul at four in the morning under a concrete underpass. They are food for the hungry, and coffee for the faithful who have kept vigil. “Don’t ask what’s the worst/that could happen/because it already happened” an early poem warns, but the work of words goes on, making meaning out of the incomprehensible. “I am a Cribbage opponent, ride to a therapist, snowboard buyer, homework checker, Mama Bear” another poem chants, listing the million tasks and roles that make up mothering. In these blazing poems, Susan Vespoli shares text messages and scraps of conversation from her murdered son, Adam, and in speaking for him she also speaks for the thousands of unhoused people who sleep in our cities’ doorways and back alleys, each and every one of them somebody’s child.”
            <br />
            <small class="author">
              –<b> Alison Luterman</b>, author of <i>In the Time of Great Fires, Feral City, Desire Zoo, See How We Almost Fly, and The Largest Possible Life.</i>
            </small>
          </p>
          <p>“I devoured these poems in one sitting—how could I not? The act of bearing witness asks—no, demands—that we not look away. In her collection, One of Them Was Mine, Susan Vespoli offers excruciating testimony to a mother’s unfathomable loss. The poems feel as if they were forged in a fist’s crucible—molten and brutally seared through “the hot slosh of pain”—and yet there is the unmistakable riverbed of tenderness and compassion shaped by the seismic rupture of a heart that keeps breaking. Out of the treacherous minefield of her son’s addiction and the incalculable horror of losing him to police violence, Vespoli finds delicate moments of grace “backlit by morning light.” Adam’s own words appear like a “flickering sequin at the end of a cigarette” to remind us of the terrifying, sacred act of mothering, and Susan’s poems, bravely walking as they do over the abyss, are reminders not just of the necessity of not looking away, but how to tell the truth with love.”
            <br />
            <small class="author">
              –<b> Maya Stein</b>, Poet Laureate of Belfast, Maine, Ninja poet, writing guide, creative adventuress, author of <i>How We Are Not Alone and poet/editor of Grief Becomes You.</i>
            </small>
          </p>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-4">
          <figure>
            <a href="https://www.finishinglinepress.com/product/blame-it-on-the-serpent-by-susan-vespoli/">
              <img src="/img/blame-it-on-the-serpent.png"
                   alt="Book cover art, Serpent Descending by Marina Petro" />
            </a>
          </figure>
          <cite>Cover Art Artist:
            <a href="http://www.marinapetro.com/"
               target="_blank">Marina Petro</a>, “Serpent Descending”
            <br />©Copyright 2021.
          </cite>
        </div>
        <div class="col-md-8">
          <h2>Blame It on the Serpent<br />
            <small><i>Note: All profits will be donated to addiction recovery groups.</i></small><br />
            <small>- To purchase a signed copy of the book, email Susan at <a href="mailto:write.wld@gmail.com"
                 target="_blank">write.wld@gmail.com </a>. Give her your mailing address and she will send you PayPal info to send the $19.99 (free shipping). Or you can order from:
              <a href="https://www.amazon.com/Blame-Serpent-Susan-Vespoli/dp/1646627326/"
                 target="_blank">Amazon</a>,
              <a href="https://www.finishinglinepress.com/product/blame-it-on-the-serpent-by-susan-vespoli/"
                 target="_blank">Finishing Line Press</a>,
              <a href="https://www.peregrinebookcompany.com/blame-it-serpent-paperback"
                 target="_blank">Peregrine Book Company </a></small>
          </h2>
          <p>“I seek out poetry for solace, for connection, and this is just what I found in Susan Vespoli’s <i>Blame It on the Serpent</i>. The poems are sharp, direct, unflinching. Vespoli invites us in as her companions as she charts a difficult journey and faces her own agency as a woman and mother.”
            <br />
            <small class="author">
              –<b> Debra Gwartney</b>, author of <i>Live Through This: A Mother’s Memoir of Runaway Daughters and I Am a Stranger Here Myself.</i>
            </small>
          </p>
          <p>“<i>Blame It on the Serpent </i>is a 3am wellness check on the loved ones who break your heart. Susan Vespoli sifts through Ziploc bags, Zigzag papers, and scorched tinfoil to confront the opioid addiction that kidnapped her children, as teeth and marriage disintegrate. Vespoli’s lines pull unconditional beauty from the wreckage of each page to accept the serenity she cannot change, but accept it slant. Vespoli writes the most vulnerable over-the-counter poems you can get without a prescription.”<br /><small class="author">–<b> Shawnte Orion</b>, author of <i>Gravity &amp; Spectacle </i></small></p>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-4">
          <figure><a href="https://kelsaybooks.com/products/cactus-as-bad-boy?_pos=1&amp;_sid=985e47e07&amp;_ss=r"><img src="/img/Cactus-as-Bad-Boy-front-cover.jpg"
                   alt="Book cover art, Cactus as Bad Boy" /></a></figure>
        </div>
        <div class="col-md-8">
          <h2>Cactus as Bad Boy<br /><small>- To purchase a signed copy of the book, email Susan at <a href="mailto:write.wld@gmail.com"
                 target="_blank">write.wld@gmail.com</a>. Give her your mailing address and she will send you PayPal info to send the $16.50 (free shipping). Or you can order from: <a href="https://www.amazon.com/Blame-Serpent-Susan-Vespoli/dp/1646627326/"
                 target="_blank">Kelsay Books </a>or
              <a href="https://www.amazon.com/Cactus-Bad-Boy-Susan-Vespoli/dp/1639800964/"
                 target="_blank">Amazon</a></small></h2>
          <p>“Frank and funny, <i>Cactus as Bad Boy </i>sings in the light of a closely-observed world. In these poems Vespoli explores
            what it means to be a woman in modern-day America, dating again after a long marriage, finding love amidst COVID,
            quarantines, zoom, and the 'masked employees at Jiffy Lube.' She moves seamlessly between the outer world and intimate
            revelations; when she considers how she's 'gone in and out / of love with the penis / depending on who / it was attached to,'
            her candor makes me feel less alone. <i>Cactus as Bad Boy </i>is an absolute delight!”<br /><small class="author">–<b> Michaela Carter</b>, author of <i>Leonora In the Morning Light</i></small></p>
          <p>These delicious, darkly humorous poems offer an intimate look at love, loss, solitude, kids, dogs, men, women, and the whole catastrophe. Playfully inventive in language and form, they invite us to romp and wrestle, then leave little puppy teeth marks on our heart. Love bites.<br /><small class="author">–<b> Alison Luterman</b>, author of <i>In the Time of Great Fires</i></small></p>
        </div>
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>
h1,
h2.subtitle {
  text-align: center;
}
h2 {
  small {
    font-size: .7em;
    display: inline-block;
    line-height: 1.4;
  }
}
p.meta {
  font-size: 16px;
  color: #999;
}
p b {
  font-weight: bold;
}
p small {
  font-size: .9em;
  display: block;
  margin-top: 5px;
  &.author {
    margin-top: .75rem;
  }
}
p.subhead {
  font-weight: 500;
  font-size: 1.1rem;
}
cite {
  font-size: .9em;
}
figure {
  margin-bottom: 2rem;
  a {
    display: block;
  }
  img {
    margin-bottom: 0;
  }
  figcaption {
    padding: 5px 10px;
    background: #fafafa;
  }
}
</style>
