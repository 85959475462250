<template>
  <article class="contact page">
    <h1>Contact</h1>
    <hr />
    <h2 class="subtitle">If you have friendly comments or questions send me a note at:&nbsp;<a href="mailto:write.wld@gmail.com">write.wld@gmail.com</a></h2>
  </article>
</template>
<style lang="scss" scoped>
article {
  text-align: center;
}
</style>
