<template>
  <article class="inspiration page">
    <div class="row">
      <div class="col-lg-6">
        <h1>Inspiration Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h1>
        <h2 class="subtitle">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h2>
      </div>
      <div class="col-lg-6"><img src="//placehold.it/800x400/333" />
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris quis egestas tortor, non malesuada enim. Cras at risus sit amet neque lobortis sodales ut sit amet tortor. Curabitur cursus diam felis, ac condimentum magna varius vel. Donec eu ipsum volutpat, interdum mauris at, cursus quam. Nulla efficitur elit ac mauris porttitor, porta faucibus nisi vestibulum. Phasellus eu ligula at odio semper lobortis. Mauris magna tellus, gravida ac hendrerit vel, rutrum eu elit. Nam auctor lorem et tincidunt gravida. Suspendisse et risus at felis gravida placerat id eu nunc. Mauris iaculis metus ipsum, sed pellentesque arcu placerat eget. Nullam vitae posuere purus. In in risus semper, placerat elit eget, feugiat lorem. Aliquam commodo, eros nec consectetur pulvinar, tellus ligula efficitur nibh, in iaculis magna turpis eget ex. Phasellus nec consectetur massa.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris quis egestas tortor, non malesuada enim. Cras at risus sit amet neque lobortis sodales ut sit amet tortor. Curabitur cursus diam felis, ac condimentum magna varius vel. Donec eu ipsum volutpat, interdum mauris at, cursus quam. Nulla efficitur elit ac mauris porttitor, porta faucibus nisi vestibulum. Phasellus eu ligula at odio semper lobortis. Mauris magna tellus, gravida ac hendrerit vel, rutrum eu elit. Nam auctor lorem et tincidunt gravida. Suspendisse et risus at felis gravida placerat id eu nunc. Mauris iaculis metus ipsum, sed pellentesque arcu placerat eget. Nullam vitae posuere purus. In in risus semper, placerat elit eget, feugiat lorem. Aliquam commodo, eros nec consectetur pulvinar, tellus ligula efficitur nibh, in iaculis magna turpis eget ex. Phasellus nec consectetur massa.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris quis egestas tortor, non malesuada enim. Cras at risus sit amet neque lobortis sodales ut sit amet tortor. Curabitur cursus diam felis, ac condimentum magna varius vel. Donec eu ipsum volutpat, interdum mauris at, cursus quam. Nulla efficitur elit ac mauris porttitor, porta faucibus nisi vestibulum. Phasellus eu ligula at odio semper lobortis. Mauris magna tellus, gravida ac hendrerit vel, rutrum eu elit. Nam auctor lorem et tincidunt gravida. Suspendisse et risus at felis gravida placerat id eu nunc. Mauris iaculis metus ipsum, sed pellentesque arcu placerat eget. Nullam vitae posuere purus. In in risus semper, placerat elit eget, feugiat lorem. Aliquam commodo, eros nec consectetur pulvinar, tellus ligula efficitur nibh, in iaculis magna turpis eget ex. Phasellus nec consectetur massa.</p>
      </div>
    </div>
  </article>
</template>
<style lang="scss" scoped></style>
