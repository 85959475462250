<template>
  <article class="page photo-gallery">
    <h1>Trash Art</h1>
    <hr>
    <ul>
      <li v-for="(item, i) in items" :key="i">
        <a href="#">
          <figure>
            <img :src="item.src" :alt="item.title">
            <figcaption>
              <h1>{{ item.title }}</h1>
              <p v-html="item.caption"></p>
            </figcaption>
          </figure>
        </a>
      </li>
    </ul>
  </article>

</template>
<script setup>
const items = [
  {src: '',
  title:'Some title',
  caption:'Some Caption'},
  {src: '',
  title:'Some title',
  caption:'Some Caption'},
  {src: '',
  title:'Some title',
  caption:'Some Caption'},
  {src: '',
  title:'Some title',
  caption:'Some Caption'}
  
]
</script>
<style scoped>
.photo-gallery{
  ul{
    display:flex;
    flex-wrap: wrap;
    list-style: none;
    margin:0;
    padding:0;
    //background:green;
  }
  li{
    padding:0 1.5%;
    width: 33.333%;
  }
}
a{
figure{
  background: rgba(0,0,0,.15);
  figcaption{
    background: rgba(0,0,0,.05);
  }
    h1{
      font-family: 'Lato', 'Avenir', Helvetica, Arial, sans-serif;
      font-weight: 700;
      margin: 0 0 5px;
      font-size: 1.2rem;
      line-height: 1.2;
      color:#111;
    }
    p{
      margin-bottom:0;
      color:#1a1a1a;
    }
}
}
</style>