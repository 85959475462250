<template>
  <article class="poems page">
    <h1>Poems &amp; Essays</h1>
    <hr />
    <div class="row">
      <div class="col-lg-4">
        <h3>Poetry</h3>
        <ul>
          <li><a href="https://oneartpoetry.com/2024/08/09/two-poems-by-susan-vespoli/">
            "Ode to the Modified Serenity Prayer" and "Driving to pick up my daughter while wearing a purple T-shirt graphic-ed with Edvard Munch’s <i>The Scream</i>" - August 2024, ONE ART: a journal of poetry
          </a></li>
          <li><a href="https://heroinchic.weebly.com/blog/poetry-by-susan-vespoli2434746">
            "Time Travel to 1989" and "Fry's Food and Drug" - August 2024 - Anti-Heroin Chic
          </a></li>
          <li><a href="https://newversenews.blogspot.com/2024/08/aftermath-found-sonnet.html">
            "Aftermath - a Found Sonnet" - August 2024 - New Verse News
          </a></li>
          <li><a href="https://www.discretionarylove.com/birds-and-bees/">
            "Birds and Bees" - July 2024 - Discretionary Love Journal</a>
          </li>
          <li><a href="https://newversenews.blogspot.com/2024/06/dear-department-of-justice.html">
            "Dear Department of Justice" - June 2024 - New Verse News</a>
          </li>
          <li><a href="https://newversenews.blogspot.com/2024/04/picking-up-settlement-check-from-my.html">
            "Picking Up the Settlement Check from My Son's Wrongful-Death Case During the Eclipse - April 2024 - New Verse News</a>
          </li>

          <li><a href="https://oneartpoetry.com/2024/01/02/after-a-long-dark-night-of-grief-by-susan-vespoli/">"After a long dark night of grief" - January 2024 - ONE ART: a journal for poetry</a>
            <br><small> (Made ONE ART's
              <a href="https://oneartpoetry.com/2024/02/01/one-arts-top-10-most-read-poets-of-january-2024/"
                 target="_blank">
                Top Ten Most-Read Poets
              </a> of January 2024.)</small>
          </li>
          <li><a href="https://gleampoets.org/issue-7/#poem-24">"Lone Lovebird" - December 2023 - Gleam Journal</a></li>
          <li><a href="https://oneartpoetry.com/2023/06/18/driving-my-granddaughter-back-to-her-dads-house-by-susan-vespoli/">"Driving My Granddaughter Back to Her Dad's House" - June 2023, ONE ART: a journal of poetry</a></li>
          <li><a href="https://newversenews.blogspot.com/2023/05/poem-for-my-middle-finger.html">"Poem For My Middle Finger" - New Verse News, May 2023</a></li>
          <li><a href="https://www.poetrydistillery.com/poems/2023/4/30/after-a-stress-induced-ocular-migraine">"After a Stress-Induced Ocular Migraine" - Poetry Distillery, April 2023</a> (Nominated for Best of the Net)</li>
          <li><a href="https://theplumtreetavern.blogspot.com/2023/01/i-want-to-focus-on-light.html">"I want to focus on light" - Plum Tree Tavern, January 2023</a></li>
          <li><a href="https://newversenews.blogspot.com/2022/12/dear-2022.html">"Dear 2022" - New Verse News, December 2022</a></li>
          <li><a href="http://heroinchic.weebly.com/blog/poetry-by-susan-vespoli3795761">"Letter to my Son's Too Short Life" - Anti-Heroin Chic, October 2022</a></li>
          <li><a href="https://newversenews.blogspot.com/2022/09/police-violence-in-reverse.html">"Police Violence in Reverse" - New Verse News, October 2022</a></li>
          <li><a href="https://www.rattle.com/orange-by-susan-vespoli/">"Orange" - Rattle, September 2022</a></li>
          <li><a href="https://img1.wsimg.com/blobby/go/72d32658-fe55-46e1-9019-5fd7c2892700/downloads/Issue%2014%2C%202022.pdf?ver=1663860131142">"I Want Sugar Again, Stupor" - Fieldstone Review, Issue 14, September 2022</a></li>
          <li><a href="https://www.rattle.com/twenty-photos-from-police-records-of-his-last-night-alive-by-susan-vespoli/">"Twenty Photos from Police Records of His Last Night Alive" - Rattle, 2022</a></li>
          <li><a href="http://heroinchic.weebly.com/blog/poetry-by-susan-vespoli7461426">Anti-Heroin Chic - Two Poems - June 2022</a></li>
          <li><a href="https://newversenews.blogspot.com/search?q=susan+vespoli">New Verse News - June 2022</a></li>
          <li><a href="https://www.rattle.com/how-to-celebrate-your-daughters-33rd-birthday-when-theres-no-going-back-by-susan-vespoli/">"How to Celebrate Your Daughter’s 33rd Birthday When There’s No Going Back" - Rattle, 2021</a></li>
          <li><a href="https://grand-little-things.com/2021/02/15/wanted-by-susan-vespoli/">"Wanted" - Grand Little Things, 2021</a></li>
          <li><a href="http://heroinchic.weebly.com/blog/poetry-by-susan-vespoli9650057">"Food Bank" - Anti-Heroin Chic, 2021</a></li>
          <li><a href="https://newversenews.blogspot.com/2021/01/thinking-about-love-during-covid-and.html">"Thinking About Love During COVID and Coups" - New Verse News, 2021</a></li>
          <li><a href="https://www.bigtablepublishing.com/blog">Two Poems (November and December 2020) at Boston Literary Magazine, Big Table Publishing</a></li>
          <li><a href="http://heroinchic.weebly.com/blog/poetry-by-susan-vespoli">Three poems at Anti-Heroin Chic online journal</a></li>
          <li><a href="https://www.rattle.com/my-son-no-longer-missing-by-susan-vespoli/">"My Son No Longer Missing" - Rattle, 2019</a></li>
          <li><a href="https://newversenews.blogspot.com/2020/04/hoarding-light.html">"Hoarding Light" - New Verse News, 2020</a></li>
          <li><a href="https://newversenews.blogspot.com/2020/03/finding-place-to-sleep-in-pandemic.html">"Finding a Place to Sleep" - New Verse News, 2020</a></li>
          <li><a href="https://newversenews.blogspot.com/2019/07/wednesday-november-4-2020.html">"Wednesday, November 4, 2020" - New Verse News, 2019</a></li>
          <li>"Chicken" and "Alex’s Teeth" also published in Howling Up to the Sky: the Opioid Epidemic anthology by Pact Press, 2018</li>
          <li><a href="https://newversenews.blogspot.com/2018/07/america-breaks_20.html">"America Breaks" – New Verse News, 2018</a></li>
          <li><a href="https://newversenews.blogspot.com/2017/10/chicken.html">"Chicken" – New Verse News, 2017, nominated for a Pushcart Prize</a></li>
          <li><a href="https://newversenews.blogspot.com/2017/07/alexs-teeth.html">"Alex’s Teeth" – New Verse News, 2017</a></li>
          <li>"I Come From a Line of Women" - Nasty Women Poets: An Unapologetic Anthology of Subversive Verse, 2017</li>
          <li>"Bless the Bee" – Mom Egg Review, 2017</li>
          <li><a href="https://newversenews.blogspot.com/2016/09/election-anxiety.html">"Election Anxiety" – New Verse News, 2016</a></li>
          <li>"Sonogram" – Mom Egg Review, 2016</li>
          <li>"Sea Otters" – Kind of a Hurricane Press</li>
          <li>"Guam" – Mom Egg Review, 2015</li>
          <li>"Weird Portland" – The Poetry Box, Keeping It Weird, 2014</li>
          <li>"Rise and Fall" – Write Bloody’s Anthology – We Will Be Shelter, Poems for Survival, 2014</li>
          <li><a href="https://newversenews.blogspot.com/2014/03/cyber-lips.html">"Cyber Lips" – New Verse News, 2014</a></li>
          <li><a href="https://newversenews.blogspot.com/2013/08/buffaloed.html">"Buffaloed" – New Verse News, 2013</a></li>
          <li>"Daffodils" and "Deer Villanelle" – OVS Magazine, 2012</li>
          <li>"Roundabout Roads" – Sleeping Cat Books Trip of a Lifetime, 2012</li>
          <li><a href="http://south85journal.com/issues/spring-2012/spring-2012-poetry/usa-in-abcs/">"USA in ABCs" – South85Journal, 2012</a></li>
          <li><a href="https://newversenews.blogspot.com/2011/10/motorcycle-road-trip-through-six-states.html">"Motorcycle Road Trip Through Six States" – New Verse News, 2011</a></li>
          <li><a href="http://www.versewisconsin.org/Issue105/poems105/vespoli.html">"Hotel Appolinaris" – Verse Wisconsin, 2011</a></li>
          <li><a href="http://www.versewisconsin.org/Issue105/poems105/vespoli.html">"Double-Abecedarian: Princess and the Pea" – Verse Wisconsin, 2011</a></li>
          <li>"Another Nice Thing About Dogs" – Monsoon Voices, 2010, A Quiet Shelter There, 2015</li>
          <li>"He Lusts for Librarians" – OVS Magazine, nominated for Pushcart Prize, 2010</li>
        </ul>
      </div>
      <div class="col-lg-4">
        <h3>Essays</h3>
        <ul>
          <li><a href="/assets/Susan-Vespoli-Essay-for-Under-the-Gum-Tree-Magazine.pdf"
               target="_blank">"Pointers for Spending 24-hours with a Five-Year-Old" - Under the Gum Tree, July 2021</a></li>
          <li><a href="http://heroinchic.weebly.com/blog/alphabet-song-by-susan-vespoli">"Alphabet Song" - Anti-Heroin Chic, 2021</a></li>
          <li><a href="https://nailedmagazine.com/editors-choice/autobiography-in-eight-hairstyles-by-susan-vespoli/?fbclid=IwAR1cLm7oN-_PVyaJDCjb6oLAIoTUpBe4ATcgHsVjpDudOAR4Gc1N2sw0DBE">"Autobiography in Eight Hairstyles" - Nailed Magazine 2019</a></li>
          <li>"Shoebox Confessions" – Emrys Foundation, 2018</li>
          <li><a href="http://www.rolereboot.org/family/details/2018-12-one-thing-you-should-know-about-protecting-your-kids/">"One Thing You Should Know About Protecting Your Kids" – Role Reboot, 2018</a></li>
          <li><a href="http://south85journal.com/issues/spring-summer-2015/non-fiction/no-chet-at-the-breakfast-table/">"No Chet at the Breakfast Table" – South85Journal, 2015</a></li>
          <li><a href="http://www.rolereboot.org/life/details/2014-10-living-dream-miserable/">"I Was Living the Dream and I Was Miserable" – Role Reboot, 2014</a></li>
          <li><a href="http://www.moderntimesmagazine.com/page17/SummerTimeTales2014_Overview/Fiction_Who_Stays_in_Phoenix_140829/Fiction_Who_Stays_in_Phoenix_140829.php">"The Sun Also Sets: Or Living in Phoenix in the Summertime" – Modern Times Magazine, 2014</a></li>
        </ul>
      </div>
      <div class="col-lg-4">
        <h3>Chapbooks</h3>
        <ul>
          <li>
            <figure><a href="https://dulcetshop.myshopify.com/products/i-come-from-a-line-of-women-susan-vespoli"><img src="/img/longlinecover.jpg" /></a>
              <figcaption>"I Come From a Line of Women" – dancing girl press, 2018</figcaption>
            </figure>
          </li>
          <li>
            <figure><a href="https://dulcetshop.myshopify.com/products/road-trip-susan-vespoli"><img src="/img/roadtripcover.png" /></a>
              <figcaption>"Road Trip" – dancing girl press, 2015</figcaption>
            </figure>
          </li>
        </ul>
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>
h1,
h2.subtitle {
  text-align: center;
}
p.meta {
  font-size: 16px;
  color: #999;
}
figure {
  margin-bottom: 2rem;
  a {
    display: block;
  }
  img {
    margin-bottom: 0;
  }
  figcaption {
    padding: 5px 10px;
    background: #fafafa;
  }
}
</style>
