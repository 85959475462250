<template>
  <nav id="nav">
    <ul class="d-flexs">
      <!-- <li>
        <router-link to="/about">About</router-link>
      </li> -->
      <li>
        <router-link to="/poems-and-essays"><span>Poems &amp; Essays</span></router-link>
      </li>
      <li>
        <router-link to="/books"><span>Books</span></router-link>
      </li>
      <li>
        <router-link to="/events"><span>Events &amp; Classes</span></router-link>
      </li>
      <li>
        <router-link to="/news"><span>News &amp; Readings</span></router-link>
      </li>
      <li>
        <router-link to="/blog"><span>Blog</span></router-link>
      </li>
      <!-- <li>
        <router-link to="/trash-art"><span>Trash</span></router-link>
      </li> -->
      <li>
        <router-link to="/contact">Contact</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Nav',
  props: {
    msg: String
  }
}
</script>

<style scoped lang="scss">
#nav {
  li {
    display: inline-block;
    /*&:first-child{
      .router-link-exact-active{
        background:transparent;
      }
    }*/
  }
  a {
    display: inline-block;
    padding: 1.5rem 1rem;
    text-decoration: none;
    color: #fff;
    &:hover {
      background: rgba(200, 200, 200, .20)
    }
    &.router-link-exact-active {
      background: rgba(200, 200, 200, .30);
      cursor: default;
    }
  }
}
</style>
