<template>
  <h1>
    <router-link to="/">Susan Vespoli</router-link>
  </h1>
</template>

<script>
export default {
  name: 'Logo'
}
</script>

<style scoped lang="scss">
h1 {
  text-align: right;
  padding: 1.5rem 1rem;
  word-wrap: none;
  //font-family: 'Special Elite', cursive;
  //font-size: 1.5rem;
  a {
    text-decoration: none;
    //color:rgb(177, 20, 20);
    color: #fafafa;
    font-weight: 900;
  }
}
</style>
