<template>
  <footer class="d-flex align-items-center justify-content-center">
    <p>All rights reserved</p>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped lang="scss">
footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4rem;
  background: #fff;
  font-size: .8rem;
}
</style>
