<template>
  <article class="not-found page">
    <h1>Shit has gone sideways!</h1>
    <h2 class="subtitle">Sorry, the page wasn't found</h2>
  </article>
</template>
<style lang="scss" scoped>
h1,
h2 {
  text-align: center;
}</style>
