<template>
  <article class="about page">
    <h1>Blog</h1>
    <hr />
    <div class="row">
      <div class="col-lg-6 mx-auto">
        <section>
          <h1>Who I Love</h1><span class="meta">March 21st, 2021</span><i>~After Aracelis Girmay’s poem, “You Are Who I Love”</i>
          <p>You are who I love, old dog Sylvia who shivers so much my granddaughter asked why the other day. Sylvia’s four kinds of medicine cut into halves and quarters, filed in a pill box by days of the week.</p>
          <p>You are who I love, neighbor guy who sits on his porch in his bathrobe, one of the only people in my neighborhood to regularly say hello to me as I walked the dogs during the pandemic.</p>
          <p>You are who I love, dog companions relegated to the couch for whining during my Zoom class. Milk bones offered up as concession.</p>
          <p>You are who I love, dust bunnies and heart confetti Molly cut up and threw in the kitchen last Thursday and I haven’t wanted to sweep it up.</p>
          <p>You are who I love, her bottom tooth that fell out last night and the photo texted by her dad. Her open-mouth smile displaying the hole.</p>
          <p>You are who I love, my 38-year-old son who I noticed last week has developed a lined forehead.</p>
          <p>You are who I love, pharmacist at the Safeway giving COVID vaccinations between waiting on customers at the counter.</p>
          <p>You are who I love, old dad turning 90 in a party hat on the zoom screen.</p>
          <p>You are who I love, last cupcake in a baggie in the freezer, fallen behind the Yasso mint ice cream bars and tilapia.</p>
          <p>You are who I love, airport employees and planes I hope to board.</p>
          <p>You are who I love, all the dogs waiting to be adopted at the shelter, especially the one that lunged at his cage door and the animal shelter worker who made sure it was securely locked and then said, he’s been here a long time.</p>
          <p>You are who I love, little kids tumbling out of their parents’ car doors at the Montessori school, my granddaughter identifying them for me from her car seat as we wait in line for her turn to be unloaded.</p>
          <p>You are who I love, daughter and son and son and three grandchildren, one of them an adult man and I was present at his birth.</p>
          <p>You are who I love, favorite faded polka-dot shirt, fabric letting go around the pocket stitching to reveal a huge gap near my heart.</p>
        </section>
        <hr />
        <section>
          <h1>Why I Write</h1><span class="meta">April 17th, 2019</span>
          <p>If I don’t write, I get fuzzy headed and dull souled.</p>
          <p>I walk around in a haze of busy-ness amidst (over) stimuli. Writing allows me to sit down, get out of my swirling head and drop into stillness except for the pen I keep moving as fast as I can.</p>
          <p>Joan Didion said, “I write entirely to find out what I'm thinking.” Yes.</p>
          <p>After I let it all hang out in my notebook, no editing or judgment, I feel lighter and can:<br />a) just let it go and move about my day, or <br />b) gather insight and clarity. </p>
          <p>How many times has my consciousness streamed through my pen to reveal some buried wisdom nugget? Some ah-ha lightning bolt?</p>
          <p>Not every time, of course, and that’s okay. Expectations are not the point. Writing is a practice, like running or meditation. It’s a chiropractic adjustment. A cheap therapist. A lifesaver. </p>
        </section>
        <hr />
        <section>
          <h1>I Want to Walk</h1><span class="meta">April 4th, 2019</span>
          <p>I want to walk like an Egyptian. I want to walk like my parents’ housecat, the FU of my tail keeping time to my dad’s streamed jazz. I want to walk proud and tall. I want to strut. Put one foot in front of the other like the keep-on-truckin’ man.</p>
          <p>I want to walk like we used to in that meditation class held in the mortuary. We walked around the grounds so consciously, putting one foot down at a time like a slow-motion footbone advertisement, noticing every bone, every blade of grass and tree limb.</p>
          <p>I want to walk like an Olympic runner who has full choreography of her body parts. Not all hesitant and slope shouldered, self-conscious. Like my mother in that little home movie I saw, her smiling and tittering while serving guests, so subservient and proud of it back then. So the wife, the mother, the hostess. Then someone said, “Look Susie, she moves just like you!” And I knew it was true. That shy inward bent like I’m circling a candle with the structure of my ribs and chest to keep it from blowing out in the wind.</p>
          <p>Oh hell, like I’m hiding. And I think I’ve gotten better, risen out of that, and unlearning that person learned in childhood. The how-to-be-nice-a-woman, the pleasant one. UNLEARN. UNLEARN. Moderation. Be real. Be you.</p>
        </section>
        <hr />
        <section>
          <h1>10 Random Things About Me</h1><span class="meta">March 27th, 2019</span>
          <ol>
            <li>I take so many photos with my phone that I regularly crash its data capacity.</li>
            <li>I used to be afraid to fly, so would drink a shot of tequila in the airport lounge to get myself on a plane.</li>
            <li>I once boarded the wrong plane and didn't figure it out till they talked about the weather in Chicago as we were planning to take off. When I brought my error to the flight attendant's attention, she escorted me off and the other passengers clapped for me.</li>
            <li>I became a vegetarian after my cat had to have his leg amputated...the leg looked too much like a turkey drumstick.</li>
            <li>I once had a job driving a van of foster kids on outings to bowling alleys and mini-golf courses.</li>
            <li>I got a D in Home Ec in high school.</li>
            <li>I opened and ran a Montessori school for 13 years.</li>
            <li>I can't dance.</li>
            <li>I dye my own roots.</li>
            <li>I don't even want to give up gluten.</li>
          </ol>
        </section>
      </div>
    </div>
  </article>
</template>
<style lang="scss" scoped>
h1 {
  text-align: center;
}
section {
  h1 {
    font-size: 1.5rem;
    line-height: 1.75rem;
    margin-bottom: 1rem;
    text-align: left;
  }
  .meta {
    display: block;
    color: #ccc;
    font-size: .8rem;
    margin-bottom: 10px;
  }
}
</style>
