<template>
  <article class="about page">
    <h1>About Susan</h1>
    <hr />
    <div class="row">
      <div class="col-md-4">
        <!--img(src="/img/Susan-as-baby-painted-with-AI.jpg")-->
        <!-- <img src="/img/sus_reading.jpg" /> -->
        <img alt="Susan Vespoli" src="/img/susan_vespoli.jpg" />
      </div>
      <div class="col-md-8">
        <p>Susan is a poet, essay writer, teacher, lover, dog walker, cookie baker,
          bargain shopper, friend. She grew up in Louisiana, North Dakota,
          California, Arizona, Guam, and now lives in Phoenix, Arizona.</p>
        <p>She learned early on that writing is a tool for a) staying sane, b) making
          one’s point to others, and c) deciphering the zaniness of the world. For
          years, she raised kids, taught Montessori, opened and directed a school,
          and soothed her inner author by eking out a few newsletters. Thanks to a
          midlife explosion in her mid-forties, she landed in a poetry class at the local
          community college where she fell madly in love with the genre. That was
          it! She sold her school, moved to a cabin in the forest, and set off to earn an
          MFA in Creative Writing from Antioch University L.A.
        </p>
        <p>Since 2010, she’s taught creative writing online, English classes at a downtown community college, rehabbed a few extreme-fixer-upper
          houses, compulsively signed up for every poetry and creative nonfiction
          class and/or workshop she could afford and fly/drive/shuttle/crawl to.
          She’s written lots of poems, essays, a couple of chapbooks (<i>dancing girl press</i>, 2015 and 2018), and three books, <i>One of Them Was Mine</i> (<i>Kelsay Books</i>, July 2023), <i>Blame It on the Serpent </i>(<i>Finishing Line Press</i>, Jan. 2022) and <i>Cactus as Bad Boy </i>(<i>Kelsay Books</i>, July 2022).</p>
        <p>Susan is a devout believer in the power of writing to
          heal/transform/illuminate. She leads virtual writing circles based on the
          Wild Writing technique, which she studied under 27 Powers omnipotent
          writing guru Laurie Wagner.</p>
      </div>
    </div>
  </article>
</template>
<style lang="scss" scoped>
h1 {
  text-align: center;
}
img {
  margin-top: 7px
}
</style>
