<template>
  <header>
    <div class="container">
      <div class="row">
        <div class="col-md flex-grow-1 d-flex justify-content-center justify-content-md-end order-md-2 align-items-center">
          <logo />
        </div>
        <div class="d-flex flex-grow-0 justify-content-center justify-content-md-start order-md-1">
          <global-nav />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Nav from '@/components/Navigation'
import Logo from '@/components/Logo'
export default {
  name: 'Header',
  components: {
    'global-nav': Nav,
    Logo
  }
}
</script>

<style scoped lang="scss">
header {
  background: #41ab5c;
  background: #9e147d;
}
</style>
