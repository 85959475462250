<template>
  <div id="app">
    <global-header></global-header>
    <main :class="route">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <transition name="fade"
                        mode="out-in">
              <router-view></router-view>
            </transition>

          </div>
        </div>
      </div>
    </main>
    <global-footer></global-footer>
  </div>
</template>
<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
export default {
  name: 'App',
  computed: {
    route() {
      return this.$route.name
    }
  },
  components: {
    'global-header': Header,
    'global-footer': Footer
  }
}
</script>
<style lang="scss">
@import 'https://fonts.googleapis.com/css?family=Lato:300,400,700,900';
@import url('https://fonts.googleapis.com/css2?family=Special+Elite&display=swap');

@import 'https://use.fontawesome.com/releases/v5.7.2/css/all.css';

@import '@/assets/scss/base';

main {
  padding: 2rem 0 6rem;
  &.home {
    background: url(assets/images/clouds2.jpg);
    background-size: cover;
    min-height: calc(100vh - 88px);
  }
}
main.home+footer {
  background: transparent;
}
</style>
